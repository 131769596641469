// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-tsx": () => import("/Users/willis/code/gatsby-blog/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/willis/code/gatsby-blog/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirm-tsx": () => import("/Users/willis/code/gatsby-blog/src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/willis/code/gatsby-blog/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thanks-tsx": () => import("/Users/willis/code/gatsby-blog/src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/willis/code/gatsby-blog/.cache/data.json")

